import React from 'react';
import { AddIcon, FolderIcon, SharedStyleTypes, colors } from '@commonsku/styles';
import { FolderContainer } from './Containers';
import useFolderActionModal from './useFolderActionModal';

export interface NewFolderProps {
    activeParent: any,
}

export const NewFolder = ({ ...props }: NewFolderProps & SharedStyleTypes) => {
    const [popup, setOpen, setParent, setType] = useFolderActionModal();

    // Only certain type can create folder
    if ('CLIENT' !== props.activeParent.parent_type) {
        return null;
    }

    return <li key='new'>
        <a
            href="/"
            className='nav-folder'
            onClick={(e) => {
                e.preventDefault();
                setParent(props.activeParent);
                setType('new');
                setOpen(true);
            }}
        >
            <FolderContainer><AddIcon />New Folder</FolderContainer>
        </a>
        {popup}
    </li>;
};
