import React from 'react';
import styled from 'styled-components';
import { Row, Box, Button, Popup, Typography, colors } from '@commonsku/styles';
import { useArtworkLibraryContext } from './context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const BoxContainer = styled(Box)`
  box-shadow: none;
  width: 100%;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: calc(100vh - 150px);
    overflow-y: auto;
  }

  .navContainer {
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 250px;
    padding-top: 20px;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    padding-bottom: 40px;
  }

  .navContainer .nav-parent {
    padding: 0.5rem 1rem;
    display: block;
    font-weight: bold;
  }

  .navContainer .nav-folder {
    font-size: 0.9em;
    display: block;
    height: 100%;
    width: 100%;
    padding: 1rem;
    font-weight: bold;
  }

  .navContainer .nav-folder.active {
    background: rgb(0, 160, 182);
    color: white;
  }

  .dropzone-container {
    position: relative;
    display: flex;
    vertical-align: bottom;
    justify-content: center;
    gap: 10px;
  }

  .dropzone-place-holder-text {
    display: grid;
    place-content: center;
    color: ${colors.teal[90]};
  }
`;

const BoxContainerInModal = styled(BoxContainer)`
  .container {
    height: 100%;
    overflow-y: auto;
  }
`;

const ArtworkLibraryContainer = ({ children }) => {
  const {
    isModal,
    modalOpen,
    setModalOpen,
  } = useArtworkLibraryContext();

  if (isModal) {
    if (!modalOpen) {
      return null;
    }
    return <Popup
      style={{ padding: 20, maxWidth: '1600px', zIndex: 10001 }}
      width='calc(100vw - 100px)'
      height='calc(100vh - 100px)'
      header={
        <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
          <Typography.H2>Artwork Library</Typography.H2>
          <div>
            <Button onClick={() => setModalOpen(false)}>Done</Button>
          </div>
        </Row>
      }>
      <DndProvider backend={HTML5Backend}>
        <BoxContainerInModal>
          <div className='container'>
            {children}
          </div>
        </BoxContainerInModal>
      </DndProvider>
    </Popup>;
  }

  return <DndProvider backend={HTML5Backend}><BoxContainer>
    <div className='container'>
      {children}
    </div></BoxContainer></DndProvider>;
};

export default ArtworkLibraryContainer;