import React, { useCallback, useMemo, useState } from 'react';
import { AlertNotification, colors, IconButton, LabeledInput, Popup, Typography } from '@commonsku/styles';
import { useArtworkLibraryContext } from './context';

export default function useFolderActionModal() {
    const { setActiveFolder, activeFolder, activeFiles } = useArtworkLibraryContext();
    const [open, setOpen] = useState(false);
    const [parent, setParent] = useState(null);
    const [type, setType] = useState(null);
    const { saveFolder, removeFolder } = useArtworkLibraryContext();
    const [folderName, setFolderName] = useState('');
    const [error, setError] = useState('');
    const [deleteConfirmText, setDeleteConfirmText] = useState('');

    let form;
    let title;
    if ('new' === type) {
        form = (
            <>
                <LabeledInput label='Folder Name' placeholder='Type here' name='folderName' value={folderName} onChange={(e) => { setFolderName(e.target.value); }}></LabeledInput>
                {'' !== error && <AlertNotification alertType='error'>{error}</AlertNotification>}
            </>
        );
        title = 'Create New Folder';
    }
    if ('delete' === type) {
        const message = `You are about to delete the "${activeFolder.folder_name}" folder with ${activeFiles.length} files.`;
        form = (
            <>
                <AlertNotification alertType='error' style={{ marginBottom: '1rem' }}><span>{message}<br /><b>You cannot undo this action!</b></span></AlertNotification>
                {activeFiles.length > 0 &&
                    <LabeledInput
                        placeholder='DELETE'
                        label='Type DELETE here'
                        name='deleteConfirm'
                        value={deleteConfirmText}
                        onChange={(e) => { setDeleteConfirmText(e.target.value); }}>
                    </LabeledInput>
                }
            </>
        );
        title = 'Are you sure?';
    }

    const actionCallback = useCallback((parent) => {
        const saveNewFolder = async () => {
            const folder = await saveFolder({
                parent_type: parent?.parent_type,
                parent_id: parent?.parent_id,
                folder_name: folderName,
            });
            setActiveFolder(folder);
        };

        switch (type) {
            case 'new':
                if ('' === folderName.trim()) {
                    setError('Folder name is empty');
                    return;
                }
                setOpen(false);
                saveNewFolder();
                break;
            case 'delete':
                removeFolder(activeFolder);
                setActiveFolder(null);
                break;
            default:
                break;
        }
    }, [activeFolder, folderName, removeFolder, saveFolder, setActiveFolder, type]);

    let disabled = false;
    if ('delete' === type && activeFiles.length > 0 && 'delete' !== deleteConfirmText.toLowerCase()) {
        disabled = true;
    }
    if ('new' === type && '' === folderName) {
        disabled = true;
    }
    const popup = useMemo(() => {
        let popup = null;
        if (open) {
            popup = (
                <Popup header={<span></span>} style={{ width: 'auto', height: 'auto', zIndex: 10000 }}>
                    {'delete' !== type && <div style={{ float: 'right' }}><button className="close-button" aria-label="Close modal" type="button" onClick={e => { e.preventDefault(); setOpen(false); }}>
                        <span aria-hidden="true" style={{ color: colors.teal[60] }}>&times;</span>
                    </button></div>}
                    <Typography.H5>{title}</Typography.H5>
                    {form}
                    <div style={{ display: 'flex', minWidth: '400px', justifyContent: 'right', flexDirection: 'row', marginBottom: '1em', marginTop: '1em' }}>
                        <IconButton variant='secondary' style={{ marginRight: '1em', minWidth: 'delete' === type ? '210px' : '100px' }} onClick={() => {
                            setDeleteConfirmText('');
                            setOpen(false);
                        }}>Cancel</IconButton>
                        <IconButton
                            style={{ minWidth: 'delete' === type ? '210px' : '100px' }}
                            disabled={disabled}
                            variant={'delete' === type ? 'error' : 'primary'}
                            onClick={() => {
                                setOpen(false); actionCallback(parent);
                            }}>
                            {'delete' === type ? 'Delete' : 'new' === type ? 'Create' : 'Confirm'}
                        </IconButton>
                    </div>
                </Popup>
            );
        }
        return popup;
    }, [actionCallback, disabled, form, open, parent, title, type]);

    return [popup, setOpen, setParent, setType];
};