import { useEffect, useCallback } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../redux/folders';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function useFolders(parent_type, parent_id) {
    const dispatch = useDispatch();
    const reduxState = useSelector((state: any) => state.folders);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll(parent_type, parent_id));
    }, [dispatch, parent_id, parent_type]);

    const save = useCallback((item) => {
        const updateCall = async () => {
            let resultedItem;
            if (item.folder_id) {
                resultedItem = await dispatch(updateItem({ id: item.folder_id, ...item }));
            } else {
                resultedItem = await dispatch(addItem(item));
            }
            toast.success("Saved successfully");
            return resultedItem;
        };

        return updateCall();
    }, [dispatch]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
            toast.success("Deleted successfully");
        };

        deleteCall();
    }, [dispatch]);

    return [
        items,
        loading,
        save,
        remove
    ];
};
